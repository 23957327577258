import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationModalInput, ModalResponse } from '../../types/modal';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrl: './confirmation-modal.component.scss'
})
export class ConfirmationModalComponent {

  constructor (private dialogRef: MatDialogRef<ConfirmationModalComponent>, @Inject(MAT_DIALOG_DATA) private modalData: ConfirmationModalInput) {}

  title?: string = "";
  body: string = "";
  cancelButtonLabel?: string = "Cancel";
  confirmButtonLabel?: string = "Confirm";

  ngOnInit() {
    this.title = this.modalData.title;
    this.body = this.modalData.body;
    this.cancelButtonLabel = this.modalData.cancelButtonLabel
    this.confirmButtonLabel = this.modalData.confirmButtonLabel
  }

  @Output() cancelBtn = new EventEmitter<string>();
  @Output() proceedBtn = new EventEmitter<string>();

  handleCancel() {
    this.dialogRef.close(ModalResponse.CANCEL)
  }

  handleProceed() {
    this.dialogRef.close(ModalResponse.CONFIRM)
  }
}