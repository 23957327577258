<div class="modal-container" data-testid="confirmationModal">
  <div class="close-container">
    <kite-icon
      (click)="handleCancel()"
      icon="ki-x"
      size="20px"
      data-testid="dismissIcon"
      class="dismiss-icon"
    ></kite-icon>
  </div>
  <div class="title-content" *ngIf="title">{{title}}</div>
  <div class="body-content">
    {{body}}
  </div>
  <div class="modal-buttons">
    <kite-button
      (click)="handleCancel()"
      buttonStyle="secondary"
      data-testid="cancelBtn"
      >{{cancelButtonLabel}}</kite-button
    >
    <kite-button (click)="handleProceed()" data-testid="continueBtn"
      >{{confirmButtonLabel}}</kite-button
    >
  </div>
</div>
  