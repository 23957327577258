export interface ConfirmationModalInput {
	title?: string,
	body: string,
	confirmButtonLabel?: string,
	cancelButtonLabel?: string,
}

export enum ModalResponse {
    CONFIRM = "accept",
    CANCEL = "cancel",
}